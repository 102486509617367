import React, { useRef, useState } from "react";
import { useHistory } from "react-router";

// Material UI Comps
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

function SearchBar() {
  let history = useHistory();

  const servers = [
    {
      value: "NA1",
      label: "NA",
    },
    {
      value: "EUN1",
      label: "EUN",
    },
    {
      value: "EUW1",
      label: "EUW",
    },
    {
      value: "JP1",
      label: "JP",
    },
    {
      value: "KR",
      label: "KR",
    },
    {
      value: "LA1",
      label: "LAN",
    },
    {
      value: "LA2",
      label: "LAS",
    },
    {
      value: "BR1",
      label: "BR",
    },
    {
      value: "OC1",
      label: "OC",
    },
    {
      value: "RU",
      label: "RU",
    },
    {
      value: "TR1",
      label: "TR",
    },
  ];

  const [server, setServers] = React.useState("");

  const handleChange = (event) => {
    setServers(event.target.value);
    console.log(event);
  };

  const summonerEl = useRef(null);
  const serverEl = useRef(null);

  const submitSearch = (event) => {
    history.push(
      `/summoner/${summonerEl.current.value}/${serverEl.current.value}`
    );
  };

  return (
    <div>
      <TextField
        inputRef={summonerEl}
        label="Summoner Name"
        type="search"
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            submitSearch(event);
          }
        }}
      />
      <TextField
        inputRef={serverEl}
        select
        label="Server"
        value={servers}
        onChange={handleChange}
        SelectProps={{
          native: true,
        }}
        //   helperText="Server"
      >
        {servers.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </div>
  );
}

export default SearchBar;
