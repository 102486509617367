import React from 'react';
import SearchBar from '../components/searchBar/SearchBar'

function HomePage() {
    return (
        <div>
            <h1>Homepage</h1>
            <SearchBar/>
        </div>
    )
}

export default HomePage
