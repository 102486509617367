import React from 'react'
import SummonerInfo from '../components/summonerInfo/SummonerInfo'

function SummonerPage() {
    return (
        <div>
            <SummonerInfo/>
        </div>
    )
}

export default SummonerPage
