import React from 'react'

function E404() {
    return (
        <div>
            404 NOT FOUND
        </div>
    )
}

export default E404
