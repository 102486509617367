import logo from './logo.svg';
import React, {useEffect, useState} from 'react'
import { Route, Switch } from 'react-router';
import { useHistory } from "react-router-dom";
import './App.css';

// Pages

import HomePage from './pages/HomePage';

import E404 from './pages/E404';
import SummonerPage from './pages/SummonerPage';
function App() {



  return (
    <div className="App">
      <Switch>
      <Route path="/summoner/:name/:server" component={SummonerPage}/>
      <Route exact path="/" component={HomePage}/>
      <Route path="*" component={E404}/> // needs to be changed to 404
      </Switch>
    </div>
    
  );
}

export default App;
