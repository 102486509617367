import React,{useState} from "react";
import axios from "axios";

function SummonerInfo() {

    const [summonerNameInfo, setSummonerNameInfo] = useState('')
    const [summonerIcon, setSummonerIcon] = useState('')
    const [summonerLevel, setSummonerLevel] = useState('')

  const url = window.location.pathname.split('/')
  const summonerName = url[2]
  const server = url[3]

  console.log(summonerName, server)
  axios.post('http://localhost:3001/api', {
    headers: {
        "Location":  window.location.href
    },
    summonerName: summonerName,
    server: server
  })
  .then(function (response) {
    console.log(response.data);
    setSummonerLevel(response.data.summonerLevel)
    setSummonerNameInfo(response.data.name)
    setSummonerIcon(response.data.profileIconId)
  })
  .catch(function (error) {
    console.log(error);
  });

  return <div>Summoner Name: {summonerNameInfo} <br /> Summoner Level: {summonerLevel} <br /> Icon ID: {summonerIcon} <br /> Server {server.replace(/[0-9]/g, '')}</div>;
}

export default SummonerInfo;
